import { CSSProperties, useRef } from 'react';
import * as React from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Column } from 'ag-grid-community';
import classnames from 'classnames';

import { ColumnToolPanel } from './ColumnToolPanel/columnToolPanel';
import { NoRowsOverlayComponent } from './NoRowsOverlayComponent';
import { AG_GRID_LOCALE_RU, DEFAULT_COL_DEF } from './utils';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './style.scss';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface IAgGridTableProps {
  tableName: string;
  columnDefs: any;
  rowData: any;
  style?: CSSProperties;
}
const AgGridTable: React.FC<IAgGridTableProps> = ({
  tableName,
  rowData,
  columnDefs,
  style,
}) => {
  const gridRef = useRef<any>();
  const isDevelop = window.location.pathname.split('/').includes('develop')
    ? '_develop'
    : '';
  const isMaster = window.location.pathname.split('/').includes('master')
    ? '_master'
    : '';
  const onColumnVisibleChange = (visible: boolean, colId: string) => {
    gridRef.current!.api.applyColumnState({
      state: [{ colId, hide: !visible }],
    });
    localStorage.setItem(
      tableName + isDevelop + isMaster,
      JSON.stringify(
        gridRef
          .current!.api.getAllGridColumns()
          .map((colDefs: Column) => [colDefs.getColId(), colDefs.isVisible()]),
      ),
    );
  };
  return (
    <>
      <ColumnToolPanel
        tableName={tableName + isDevelop + isMaster}
        colDef={columnDefs}
        onColumnVisibleChange={onColumnVisibleChange}
      />
      <div
        className={classnames(
          'ag-theme-quartz table__container',
          !rowData.length && 'empty',
        )}
        style={{
          width: '100%',
          ...style,
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          sideBar="columns"
          // domLayout='autoHeight'
          columnDefs={columnDefs}
          localeText={AG_GRID_LOCALE_RU}
          defaultColDef={DEFAULT_COL_DEF}
          noRowsOverlayComponent={NoRowsOverlayComponent}
          enableCellTextSelection
          onDragStopped={e => {
            localStorage.setItem(
              tableName + isDevelop + isMaster,
              JSON.stringify(
                e.api
                  .getAllGridColumns()
                  .map(col => [col.getColId(), col.isVisible()]),
              ),
            );
          }}
        />
      </div>
    </>
  );
};

export default AgGridTable;
