import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { selectWells } from 'store/projectSlice';

import { Legend } from '../Legend';
import { IChartInfo } from '../types';
import { options } from '../utils';
import useChartData from './hooks/use-chart-data';

import '../style.scss';
import 'Pages/Project/ResultsPanel/chartStyle.scss';

const MOECChart: React.FC = () => {
  const chartRef = useRef();
  const wells = useSelector(selectWells);
  const [info, setInfo] = useState<IChartInfo[]>([]);

  useEffect(() => {
    const data: IChartInfo[] = [];
    data.push(
      ...wells.items
        .filter(well => well.current_state === 'IN_WORK')
        .map(well => {
          return {
            id: well.id,
            type: 'WELL',
            name: well.name,
            isVisible: true,
          } as IChartInfo;
        }),
    );
    // data.push(
    //   ...pumps.items.map(pump => {
    //     return {
    //       id: pump.uid,
    //       type: 'PUMP',
    //       name: pump.name,
    //       isVisible: true,
    //     } as IChartInfo;
    //   }),
    // );
    // data.push(
    //   ...separators.items.map(sep => {
    //     return {
    //       id: sep.uid,
    //       type: 'SEPARATOR',
    //       name: sep.name,
    //       isVisible: true,
    //     } as IChartInfo;
    //   }),
    // );
    setInfo(data);
  }, [wells]);

  const chartData = useChartData(info);

  return (
    <div className="results__single-chart">
      <Line data={chartData} ref={chartRef} options={options(true) as any} />
      <Legend info={info} setInfo={setInfo} />
    </div>
  );
};

export default MOECChart;
