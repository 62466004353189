import {
  CompareCellComparator,
  CompareCellFilterGetter,
  CompareCellRenderer,
} from '../../../../../Components/AgGridTable/CompareCellRenderer/compareCellRenderer';
import { StatusRenderer } from '../../../../../Components/AgGridTable/StatusRenderer/statusRenderer';

const compareCellColumnDefs = {
  cellRenderer: CompareCellRenderer,
  filterValueGetter: CompareCellFilterGetter,
  comparator: CompareCellComparator,
  filter: 'agNumberColumnFilter',
};
export const compareWellTableColumnDefs = [
  {
    colId: '0',
    headerName: 'Факт. статус',
    field: 'preset_current_state',
    cellRenderer: StatusRenderer,
    cellClass: 'ag-cell__content-left',
    initialWidth: 120,
  },
  {
    colId: '1',
    headerName: 'Тек. статус',
    field: 'calc_current_state',
    cellRenderer: StatusRenderer,
    cellClass: 'ag-cell__content-left',
    initialWidth: 120,
  },
  {
    colId: '2',
    headerName: 'Название',
    field: 'well_name',
    cellClass: 'ag-cell__content-left',
  },
  {
    colId: '3',
    headerName: 'Способ эксплуатации',
    field: 'operation_type',
    cellClass: 'ag-cell__content-left',
    minWidth: 170,
  },
  {
    colId: '4',
    headerName: 'Режим работы',
    field: 'control_type',
    cellClass: 'ag-cell__content-left',
  },
  { colId: '5', headerName: 'Факт. офсет, мин', field: 'fact_offset_time' },
  {
    colId: '6',
    headerName: 'Тек. офсет, мин',
    field: 'base_offset_time',
    ...compareCellColumnDefs,
  },
  {
    colId: '7',
    headerName: 'Факт. лин. давление, атм',
    field: 'fact_pressure',
    minWidth: 190,
  },
  {
    colId: '8',
    headerName: 'Тек. лин.давление, атм',
    field: 'base_pressure',
    minWidth: 180,
    ...compareCellColumnDefs,
  },
  {
    colId: '9',
    headerName: 'Факт. расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'fact_fluid_flow_rate',
    minWidth: 190,
  },
  {
    colId: '10',
    headerName: 'Тек. расход жидкости, м\u00B3\u2060/\u2060сут',
    field: 'base_fluid_flow_rate',
    minWidth: 190,
    ...compareCellColumnDefs,
  },
  {
    colId: '11',
    headerName: 'Факт. расход газа, тыс.\u00a0м\u00B3\u2060/\u2060сут',
    field: 'fact_gas_flow_rate',
    minWidth: 190,
  },
  {
    colId: '12',
    headerName: 'Тек. расход газа, тыс.\u00a0м\u00B3\u2060/\u2060сут',
    field: 'base_gas_flow_rate',
    minWidth: 190,
    ...compareCellColumnDefs,
  },
  {
    colId: '13',
    headerName: 'Факт. расход нефти, т\u2060/\u2060сут',
    field: 'fact_oil_flow_rate',
    minWidth: 190,
  },
  {
    colId: '14',
    headerName: 'Тек. расход нефти, т\u2060/\u2060сут',
    field: 'base_oil_flow_rate',
    minWidth: 190,
    ...compareCellColumnDefs,
  },
  {
    colId: '15',
    headerName: 'Факт. управляющее воздействие, Гц',
    field: 'fact_control_freq',
    minWidth: 190,
  },
  {
    colId: '16',
    headerName: 'Тек. управляющее воздействие, Гц',
    field: 'base_control_freq',
    ...compareCellColumnDefs,
    minWidth: 190,
  },
  {
    colId: '17',
    headerName:
      'Факт. управляющее воздействие, тыс.\u00a0м\u00B3\u2060/\u2060сут',
    field: 'fact_control_flow',
    minWidth: 200,
  },
  {
    colId: '18',
    headerName:
      'Тек. управляющее воздействие, тыс.\u00a0м\u00B3\u2060/\u2060сут',
    field: 'base_control_flow',
    ...compareCellColumnDefs,
    minWidth: 200,
  },
  {
    colId: '19',
    headerName: 'Факт. длит. интервала работы, мин',
    field: 'fact_work_time',
    minWidth: 200,
  },
  {
    colId: '20',
    headerName: 'Тек. длит. интервала работы, мин',
    field: 'base_work_time',
    ...compareCellColumnDefs,
    minWidth: 200,
  },
  {
    colId: '21',
    headerName: 'Факт. длит. интервала накопления, мин',
    field: 'fact_accumulation_time',
    minWidth: 200,
  },
  {
    colId: '22',
    headerName: 'Тек. длит. интервала накопления, мин',
    field: 'base_accumulation_time',
    ...compareCellColumnDefs,
    minWidth: 200,
  },
];
